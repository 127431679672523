import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchRecentAllScanList = createAsyncThunk(
  "admin/recent-scan-all-list",
  async ({limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.recentAllScanListURL.replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const recentAllScanListSlice = createSlice({
  name: "recent-scan-all-list",
  initialState: {
    recentAllScanList: [],
    recentAllScanListFetching: false,
    recentAllScanListSuccess: false,
    recentAllScanListError: false,
    recentAllScanListErrorMessage: "",
    recentAllScanCount: null,
  },
  reducers: {
    recentAllScanListClearState: (state) => {
      state.recentAllScanListError = false;
      state.recentAllScanListSuccess = false;
      state.recentAllScanListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchRecentAllScanList.fulfilled]: (state, { payload }) => {
      state.recentAllScanList = [];
      payload.data.results.forEach((item) =>
        state.recentAllScanList.push(item)
      );
      state.recentAllScanCount = payload.data.count;
      state.recentAllScanListFetching = false;
      state.recentAllScanListSuccess = true;
      return state;
    },
    [fetchRecentAllScanList.rejected]: (state, action) => {
      state.recentAllScanListFetching = false;
      state.recentAllScanListError = true;
      state.recentAllScanListErrorMessage = action?.payload;
    },
    [fetchRecentAllScanList.pending]: (state) => {
      state.recentAllScanListFetching = true;
    },
  },
});

export const { recentAllScanListClearState } = recentAllScanListSlice.actions;

export default recentAllScanListSlice.reducer;
