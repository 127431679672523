import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchUserPointHistoryList = createAsyncThunk(
  "admin/users-point-list",
  async ({ limit, offset ,userID  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.userPointHistoryListURL.replace("{id}",userID).replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const userPointHistoryListSlice = createSlice({
  name: "users-point-list",
  initialState: {
    userPointHistoryList: [],
    userPointHistoryFetching: false,
    userPointHistorySuccess: false,
    userPointHistoryError: false,
    userPointHistoryErrorMessage: "",
    userHistoryCount: null,
  },
  reducers: {
    userPointHistoryClearState: (state) => {
      state.userPointHistoryError = false;
      state.userPointHistorySuccess = false;
      state.userPointHistoryFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchUserPointHistoryList.fulfilled]: (state, { payload }) => {
      state.userPointHistoryList = [];
      payload.data.results.forEach((item) => state.userPointHistoryList.push(item));
      state.userHistoryCount = payload.data.count;
      state.userPointHistoryFetching = false;
      state.userPointHistorySuccess = true;
      return state;
    },
    [fetchUserPointHistoryList.rejected]: (state, action) => {
      state.userPointHistoryFetching = false;
      state.userPointHistoryError = true;
      state.userPointHistoryErrorMessage = action?.payload;
    },
    [fetchUserPointHistoryList.pending]: (state) => {
      state.userPointHistoryFetching = true;
    },
  },
});

export const { userPointHistoryClearState } = userPointHistoryListSlice.actions;

export default userPointHistoryListSlice.reducer;
