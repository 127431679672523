import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const deleteUser = createAsyncThunk(
  "admin/delete-user",
  async ({ userID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.userDetailsURL.replace("{id}", userID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const userDeleteSlice = createSlice({
  name: "delete-user",
  initialState: {
    deleteUserFetching: false,
    deleteUserSuccess: false,
    deleteUserError: false,
    deleteUserErrorMessage: "",
  },
  reducers: {
    deleteUserClearState: (state) => {
      state.deleteUserError = false;
      state.deleteUserSuccess = false;
      state.deleteUserFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.deleteUserFetching = false;
      state.deleteUserSuccess = true;
      return state;
    },
    [deleteUser.rejected]: (state, action) => {
      state.deleteUserFetching = false;
      state.deleteUserError = true;
      state.deleteUserErrorMessage = action?.payload;
    },
    [deleteUser.pending]: (state) => {
      state.deleteUserFetching = true;
    },
  },
});

export const { deleteUserClearState } = userDeleteSlice.actions;

export default userDeleteSlice.reducer;
