import axios from "axios";

const actionHandler = (payload) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Cache-Control"] = "no-cache";

  const token = localStorage.getItem("admin-token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  return new Promise((resolve, reject) => {
    payload.baseURL = "https://admin.helvitindia.com/api_helvet_india/api/";
    //Remove before production
    // payload.baseURL = "https://dev.enfono.com/api_helvet_india/api/";

    axios(payload)
      .then((response) => {
        let resp = response.data;
        if (response.status >= 200 && response.status < 300) {
          resolve(response);
        } else {
          console.log("failure", response);
          reject(response);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};


axios.interceptors.response.use(undefined, function (err) {
  var statusCode = err.status;
  if (statusCode == undefined) {
    // Server needs to specify CORS headers in the response
    // Basically `ACCESS-CONTROL-ALLOW-ORIGIN: *`
    // Otherwise, these kinda issues happen

    var lineSplit = err.toString().split("\n")[0].split(" ");
    statusCode = lineSplit[lineSplit.length - 1];
  }

  console.log("intercepter statuscode: ", statusCode);

  return new Promise(() => {
    if (statusCode == 401 && err.config && !err.config.__isRetryRequest) {
      // Got an unauthorized, logout the staff
      localStorage.removeItem("admin-token");
      window.location.pathname = "/auth/sign-in";
      
    }
    throw err;
  });
});

export default {
  /* auth URLs */
  loginURL: "auth/login/", // [POST]
  logoutURL: "auth/logout/", // [POST]
  profileURL: "auth/profile/", // [GET, PUT]
  
  //Dashboard
  dashboardDataURL: "web_admin/dashboard-card-data/", // [GET]
  recentScanListURL: "web_admin/recent-claim-request/", // [GET]

  //Users
  usersListURL: "web_admin/users/?limit={limit}&offset={offset}&search={search}", //[GET]
  userPostURL: "web_admin/users/", //[POST]
  userDetailsURL: "web_admin/user/{id}/", //[GET, PATCH, DELETE]
  unverifiedUserListURL: "web_admin/unverified-users/?limit={limit}&offset={offset}&search={search}", //[GET]
  verifyUserURL: "web_admin/user-verification/{id}/", //[PUT]
  userPointHistoryListURL: "web_admin/user/{id}/redeemed-points-history/?limit={limit}&offset={offset}", //[GET]
  userPointsDetailsURL: "web_admin/user/{id}/points/",
  userPointRedeemURL: "web_admin/user/{id}/redeem-points/",
  userQRScanListURL:"web_admin/user/{id}/scanned-qr-codes/?limit={limit}&offset={offset}", //[GET]

  //Points
  pointsListURL: "web_admin/all_user_points?limit={limit}&offset={offset}&search={search}", //[GET]

  //Recent Scan
  recentAllScanListURL: "web_admin/qr_claim_requests/?limit={limit}&offset={offset}", //[GET]
  recentVerifyScanURL: "web_admin/qr_claim_requests/{id}/", //[PUT]

  approvedScanListURL:'web_admin/approved_or_rejected_qr/?status=approved&limit={limit}&offset={offset}',

  //Rejected Scan
  rejectedScanListURL: "web_admin/approved_or_rejected_qr/?status=rejected&limit={limit}&offset={offset}", //[GET]

  //QR Code
  barcodeGroupListURL: "web_admin/qr-groups/?limit={limit}&offset={offset}", //[GET]
  barcodeListURL: "web_admin/qr-codes/?group={group}&limit={limit}&offset={offset}", //[GET]
  printBatchURL: "web_admin/qr-codes/?group={group}&limit=500", //[POST]
  barcodePostURL: "web_admin/qr-codes/", //[POST]
  latestBarcodeListURL: "web_admin/qr-codes/last/?limit={limit}&offset={offset}",
  printBarcodeListURL:'web_admin/qr-codes/last/?limit=500', //[GET]
  barcodeDetailsURL: "web_admin/qr-code/{id}/", //[GET, PATCH, DELETE]
  barcodeBatchEditURL: "web_admin/qr_group/{id}/", //[PUT]
  
  //Staffs
  staffListURL: "web_admin/staffs/?limit={limit}&offset={offset}&search={search}", //[GET]
  staffPostURL: "web_admin/staffs/", //[POST]
  staffDetailsURL: "web_admin/staff/{id}/", //[GET, PATCH, DELETE]

  //Settings
  organizationDetailsURL: "web_admin/organization/", //[GET, PATCH]

  //Reject Reason 
  rejectReasonListURL: "web_admin/rejection_reasons/?limit={limit}&offset={offset}", //[GET]
  rejectReasonPostURL: "web_admin/rejection_reasons/", //[POST]
  rejectReasonDetailsURL: "web_admin/rejection_reason/{id}/", //[GET, PATCH, DELETE]

  //Redeem Requests 
  redeemRequestListURL: "web_admin/redeem_requests/?limit={limit}&offset={offset}", //[GET]
  verifyRedeemRequestURL: "web_admin/redeem_request/{id}/", //[PUT]



  actionHandler,
};
