import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchRejectedScanList = createAsyncThunk(
  "admin/rejected-scan-all-list",
  async ({limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.rejectedScanListURL.replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const rejectedScanSlice = createSlice({
  name: "rejected-scan-all-list",
  initialState: {
    rejectedScanList: [],
    rejectedScanListFetching: false,
    rejectedScanListSuccess: false,
    rejectedScanListError: false,
    rejectedScanListErrorMessage: "",
    rejectedScanCount: null,
  },
  reducers: {
    rejectedScanListClearState: (state) => {
      state.rejectedScanListError = false;
      state.rejectedScanListSuccess = false;
      state.rejectedScanListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchRejectedScanList.fulfilled]: (state, { payload }) => {
      state.rejectedScanList = [];
      payload.data.results.forEach((item) =>
        state.rejectedScanList.push(item)
      );
      state.rejectedScanCount = payload.data.count;
      state.rejectedScanListFetching = false;
      state.rejectedScanListSuccess = true;
      return state;
    },
    [fetchRejectedScanList.rejected]: (state, action) => {
      state.rejectedScanListFetching = false;
      state.rejectedScanListError = true;
      state.rejectedScanListErrorMessage = action?.payload;
    },
    [fetchRejectedScanList.pending]: (state) => {
      state.rejectedScanListFetching = true;
    },
  },
});

export const { rejectedScanListClearState } = rejectedScanSlice.actions;

export default rejectedScanSlice.reducer;
