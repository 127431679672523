import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchApprovedScanList = createAsyncThunk(
  "admin/approved-scan-all-list",
  async ({limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.approvedScanListURL.replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const approvedAllScanListSlice = createSlice({
  name: "approved-scan-all-list",
  initialState: {
    approvedScanList: [],
    approvedScanListFetching: false,
    approvedScanListSuccess: false,
    approvedScanListError: false,
    approvedScanListErrorMessage: "",
    approvedScanCount: null,
  },
  reducers: {
    approvedScanListClearState: (state) => {
      state.approvedScanListError = false;
      state.approvedScanListSuccess = false;
      state.approvedScanListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchApprovedScanList.fulfilled]: (state, { payload }) => {
      state.approvedScanList = [];
      payload.data.results.forEach((item) =>
        state.approvedScanList.push(item)
      );
      state.approvedScanCount = payload.data.count;
      state.approvedScanListFetching = false;
      state.approvedScanListSuccess = true;
      return state;
    },
    [fetchApprovedScanList.approved]: (state, action) => {
      state.approvedScanListFetching = false;
      state.approvedScanListError = true;
      state.approvedScanListErrorMessage = action?.payload;
    },
    [fetchApprovedScanList.pending]: (state) => {
      state.approvedScanListFetching = true;
    },
  },
});

export const { approvedScanListClearState } = approvedAllScanListSlice.actions;

export default approvedAllScanListSlice.reducer;
