import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const verifyUserRequest = createAsyncThunk(
  "admin/user-verify",
  async ({ userID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.verifyUserURL.replace("{id}", userID),
        method: "PUT",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const userRequestVerifySlice = createSlice({
  name: "user-verify",
  initialState: {
    userVerifyFetching: false,
    userVerifySuccess: false,
    userVerifyError: false,
    userVerifyErrorMessage: "",
  },
  reducers: {
    clearUserVerifyState: (state) => {
      state.userVerifyError = false;
      state.userVerifySuccess = false;
      state.userVerifyFetching = false;

      return state;
    },
  },
  extraReducers: {
    [verifyUserRequest.fulfilled]: (state, { payload }) => {
      state.userVerifyFetching = false;
      state.userVerifySuccess = true;
      return state;
    },
    [verifyUserRequest.rejected]: (state, action) => {
      state.userVerifyFetching = false;
      state.userVerifyError = true;
      state.userVerifyErrorMessage = action?.payload;
    },
    [verifyUserRequest.pending]: (state) => {
      state.userVerifyFetching = true;
    },
  },
});

export const { clearUserVerifyState } = userRequestVerifySlice.actions;

export default userRequestVerifySlice.reducer;
