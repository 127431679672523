import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const verifyRecentScan = createAsyncThunk(
  "admin/recent-scan-verify",
  async ({ qrCodeID ,payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.recentVerifyScanURL.replace("{id}", qrCodeID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const verifyRecentScanSlice = createSlice({
  name: "recent-scan-verify",
  initialState: {
    verifyScanFetching: false,
    verifyScanSuccess: false,
    verifyScanError: false,
    verifyScanErrorMessage: "",
  },
  reducers: {
    clearVerifyScanState: (state) => {
      state.verifyScanError = false;
      state.verifyScanSuccess = false;
      state.verifyScanFetching = false;

      return state;
    },
  },
  extraReducers: {
    [verifyRecentScan.fulfilled]: (state, { payload }) => {
      state.verifyScanFetching = false;
      state.verifyScanSuccess = true;
      return state;
    },
    [verifyRecentScan.rejected]: (state, action) => {
      state.verifyScanFetching = false;
      state.verifyScanError = true;
      state.verifyScanErrorMessage = action?.payload;
    },
    [verifyRecentScan.pending]: (state) => {
      state.verifyScanFetching = true;
    },
  },
});

export const { clearVerifyScanState } = verifyRecentScanSlice.actions;

export default verifyRecentScanSlice.reducer;
