import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchPrintBarcodeList = createAsyncThunk(
  "admin/printBarcode-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.printBarcodeListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const printBarcodeListSlice = createSlice({
  name: "printBarcode-list",
  initialState: {
    printBarcodeList: [],
    printBarcodeFetching: false,
    printBarcodeSuccess: false,
    printBarcodeError: false,
    printBarcodeErrorMessage: "",
    printBarcodeCount: null,
  },
  reducers: {
    printBarcodeClearState: (state) => {
      state.printBarcodeError = false;
      state.printBarcodeSuccess = false;
      state.printBarcodeFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchPrintBarcodeList.fulfilled]: (state, { payload }) => {
      state.printBarcodeList = [];
      payload.data.results.forEach((item) => state.printBarcodeList.push(item));
      state.printBarcodeCount = payload.data.count;
      state.printBarcodeFetching = false;
      state.printBarcodeSuccess = true;
      return state;
    },
    [fetchPrintBarcodeList.rejected]: (state, action) => {
      state.printBarcodeFetching = false;
      state.printBarcodeError = true;
      state.printBarcodeErrorMessage = action?.payload;
    },
    [fetchPrintBarcodeList.pending]: (state) => {
      state.printBarcodeFetching = true;
    },
  },
});

export const { printBarcodeClearState } = printBarcodeListSlice.actions;

export default printBarcodeListSlice.reducer;
