import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchRecentScanList = createAsyncThunk(
  "admin/recent-scan-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.recentScanListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const recentScanListSlice = createSlice({
  name: "recent-scan-list",
  initialState: {
    recentScanList: [],
    recentScanListFetching: false,
    recentScanListSuccess: false,
    recentScanListError: false,
    recentScanListErrorMessage: "",
  },
  reducers: {
    recentScanListClearState: (state) => {
      state.recentScanListError = false;
      state.recentScanListSuccess = false;
      state.recentScanListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchRecentScanList.fulfilled]: (state, { payload }) => {
      state.recentScanList = [];
      payload.data.results.forEach((item) => state.recentScanList.push(item));
      state.recentScanListFetching = false;
      state.recentScanListSuccess = true;
      return state;
    },
    [fetchRecentScanList.rejected]: (state, action) => {
      state.recentScanListFetching = false;
      state.recentScanListError = true;
      state.recentScanListErrorMessage = action?.payload;
    },
    [fetchRecentScanList.pending]: (state) => {
      state.recentScanListFetching = true;
    },
  },
});

export const { recentScanListClearState } = recentScanListSlice.actions;

export default recentScanListSlice.reducer;
