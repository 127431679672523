import React, { memo, lazy, Suspense } from "react";

//router
import { Switch, Redirect } from "react-router";

import Loader from "../components/Loader";
//layoutpages
import Default from "../layouts/dashboard/default";
import Simple from "../layouts/dashboard/simple";
import Authmiddleware from "../router/Authmiddleware";

import ScrollTop from "../components/ScrollTop";

const userRoutes = [
  { path: "/dashboard", component: Default },
  { path: "/", component: () => <Redirect to="/dashboard" /> },
  { path: "/users/:active_tab?", component: Default },
  { path: "/users/:userID/:active_tab?", component: Default },
  { path: "/staff", component: Default },
  { path: "/recent-scans", component: Default },
  { path: "/approved-scans", component: Default },
  { path: "/failed-scans", component: Default },
  { path:'/redeem-request', component: Default},
  { path: "/points", component: Default },
  { path: "/barcode/:active_tab?", component: Default },
  { path: "/barcode/barcode-list/:barcodeID", component: Default },
  { path: "/settings/:active_tab", component: Default },
  { path: "/errors/error404",component: Simple,},
];

const authRoutes = [
  { path: "/auth", component: Simple },
];

const IndexRouters = memo(() => {
  return (
    <ScrollTop>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    </ScrollTop>
  );
});

IndexRouters.displayName = "IndexRouters";
export default IndexRouters;
