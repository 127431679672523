import { memo, Fragment } from "react";
import image from "../../../assets/images/Vector.svg";
import image2 from "../../../assets/images/Logo.png";
const Logo = memo((props) => {
  return (
    <Fragment>
      <div className="logo-main">
        <div className="logo-normal ms-3">
          <img width="155px" src={image} alt="" />
        </div>
        <div className="logo-mini">
          <svg
            width="35"
            height="35"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.592285"
              y="0.477051"
              width="39.9848"
              height="38.9106"
              rx="8.36923"
              fill="#284D77"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.2764 11.4202C14.706 10.858 14.706 9.94647 15.2764 9.38426L19.5209 5.19998C20.0912 4.63777 21.0159 4.63777 21.5862 5.19998L25.8308 9.38426C26.4011 9.94647 26.4011 10.858 25.8308 11.4202L21.5862 15.6045C21.0159 16.1667 20.0912 16.1667 19.5209 15.6045L15.2764 11.4202ZM19.7752 20.4538C19.4901 20.1727 19.4901 19.7169 19.7752 19.4358L20.0571 19.1579C20.3423 18.8768 20.8046 18.8768 21.0898 19.1579L21.3717 19.4358C21.6569 19.7169 21.6569 20.1727 21.3717 20.4538L21.0898 20.7317C20.8046 21.0128 20.3423 21.0128 20.0571 20.7317L19.7752 20.4538ZM25.2935 18.9483C24.7232 19.5105 24.7232 20.422 25.2935 20.9842L29.538 25.1685C30.1084 25.7307 31.033 25.7307 31.6033 25.1685L35.8479 20.9842C36.4182 20.422 36.4182 19.5105 35.8479 18.9483L31.6033 14.764C31.033 14.2018 30.1084 14.2018 29.538 14.764L25.2935 18.9483ZM5.32129 20.9735C4.75098 20.4113 4.75098 19.4998 5.32129 18.9376L9.56586 14.7533C10.1362 14.1911 11.0608 14.1911 11.6311 14.7533L15.8757 18.9376C16.446 19.4998 16.446 20.4113 15.8757 20.9735L11.6311 25.1578C11.0608 25.72 10.1362 25.72 9.56586 25.1578L5.32129 20.9735ZM15.2764 28.4444C14.706 29.0066 14.706 29.9181 15.2764 30.4803L19.5209 34.6646C20.0912 35.2268 21.0159 35.2268 21.5862 34.6646L25.8308 30.4803C26.4011 29.9181 26.4011 29.0066 25.8308 28.4444L21.5862 24.2601C21.0159 23.6979 20.0912 23.6979 19.5209 24.2601L15.2764 28.4444Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </Fragment>
  );
});

Logo.displayName = "Logo";
export default Logo;
