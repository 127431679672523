import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const createUser = createAsyncThunk(
  "admin/create-users",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.userPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const createUserSlice = createSlice({
  name: "create-users",
  initialState: {
    createUserFetching: false,
    createUserSuccess: false,
    createUserError: false,
    createUserErrorMessage: "",
  },
  reducers: {
    clearCreateUserState: (state) => {
      state.createUserError = false;
      state.createUserSuccess = false;
      state.createUserFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createUser.fulfilled]: (state, { payload }) => {
      state.createUserFetching = false;
      state.createUserSuccess = true;
      return state;
    },
    [createUser.rejected]: (state, action) => {
      state.createUserFetching = false;
      state.createUserError = true;
      state.createUserErrorMessage = action?.payload;
    },
    [createUser.pending]: (state) => {
      state.createUserFetching = true;
    },
  },
});

export const { clearCreateUserState } = createUserSlice.actions;

export default createUserSlice.reducer;
