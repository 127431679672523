import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchUserQRScans = createAsyncThunk(
  "admin/users-qr-scans-list",
  async ({ limit, offset ,userID  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.userQRScanListURL.replace("{id}",userID).replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const userQRScansListSlice = createSlice({
  name: "users-qr-scans-list",
  initialState: {
    userQRScansList: [],
    userQRScansFetching: false,
    userQRScansSuccess: false,
    userQRScansError: false,
    userQRScansErrorMessage: "",
    userQRScanCount: null,
  },
  reducers: {
    userQRScansClearState: (state) => {
      state.userQRScansError = false;
      state.userQRScansSuccess = false;
      state.userQRScansFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchUserQRScans.fulfilled]: (state, { payload }) => {
      state.userQRScansList = [];
      payload.data.results.forEach((item) => state.userQRScansList.push(item));
      state.userQRScanCount = payload.data.count;
      state.userQRScansFetching = false;
      state.userQRScansSuccess = true;
      return state;
    },
    [fetchUserQRScans.rejected]: (state, action) => {
      state.userQRScansFetching = false;
      state.userQRScansError = true;
      state.userQRScansErrorMessage = action?.payload;
    },
    [fetchUserQRScans.pending]: (state) => {
      state.userQRScansFetching = true;
    },
  },
});

export const { userQRScansClearState } = userQRScansListSlice.actions;

export default userQRScansListSlice.reducer;
