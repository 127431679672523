import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const editBarcode = createAsyncThunk(
  "admin/barcode-edit",
  async ({ payload ,barcodeID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.barcodeBatchEditURL.replace("{id}",barcodeID),
        method: "PUT",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const barcodeEditSlice = createSlice({
  name: "barcode-edit",
  initialState: {
    editBarcodeFetching: false,
    editBarcodeSuccess: false,
    editBarcodeError: false,
    editBarcodeErrorMessage: "",
  },
  reducers: {
    clearEditBarcodeState: (state) => {
      state.editBarcodeError = false;
      state.editBarcodeSuccess = false;
      state.editBarcodeFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editBarcode.fulfilled]: (state, { payload }) => {
      state.editBarcodeFetching = false;
      state.editBarcodeSuccess = true;
      return state;
    },
    [editBarcode.rejected]: (state, action) => {
      state.editBarcodeFetching = false;
      state.editBarcodeError = true;
      state.editBarcodeErrorMessage = action?.payload;
    },
    [editBarcode.pending]: (state) => {
      state.editBarcodeFetching = true;
    },
  },
});

export const { clearEditBarcodeState } = barcodeEditSlice.actions;

export default barcodeEditSlice.reducer;
