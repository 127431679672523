import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchUsersList = createAsyncThunk(
  "admin/users-list",
  async ({ limit, offset ,search  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.usersListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}", search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const userListSlice = createSlice({
  name: "users-list",
  initialState: {
    usersList: [],
    userListFetching: false,
    userListSuccess: false,
    userListError: false,
    userListErrorMessage: "",
    userCount: null,
  },
  reducers: {
    userListClearState: (state) => {
      state.userListError = false;
      state.userListSuccess = false;
      state.userListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchUsersList.fulfilled]: (state, { payload }) => {
      state.usersList = [];
      payload.data.results.forEach((item) => state.usersList.push(item));
      state.userCount = payload.data.count;
      state.userListFetching = false;
      state.userListSuccess = true;
      return state;
    },
    [fetchUsersList.rejected]: (state, action) => {
      state.userListFetching = false;
      state.userListError = true;
      state.userListErrorMessage = action?.payload;
    },
    [fetchUsersList.pending]: (state) => {
      state.userListFetching = true;
    },
  },
});

export const { userListClearState } = userListSlice.actions;

export default userListSlice.reducer;
