import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchPrintGroupQRList = createAsyncThunk(
  "admin/print-QR-list",
  async ({group}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.printBatchURL.replace("{group}", group),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const printBatchQRSlice = createSlice({
  name: "print-QR-list",
  initialState: {
    printQRListList: [],
    printBatchQRFetching: false,
    printBatchQRSuccess: false,
    printBatchQRError: false,
    printBatchQRErrorMessage: "",
    barcodeCount: null,
  },
  reducers: {
    printBatchQRClearState: (state) => {
      state.printBatchQRError = false;
      state.printBatchQRSuccess = false;
      state.printBatchQRFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchPrintGroupQRList.fulfilled]: (state, { payload }) => {
      state.printQRListList = [];
      payload.data.results.forEach((item) => state.printQRListList.push(item));
      state.barcodeCount = payload.data.count;
      state.printBatchQRFetching = false;
      state.printBatchQRSuccess = true;
      return state;
    },
    [fetchPrintGroupQRList.rejected]: (state, action) => {
      state.printBatchQRFetching = false;
      state.printBatchQRError = true;
      state.printBatchQRErrorMessage = action?.payload;
    },
    [fetchPrintGroupQRList.pending]: (state) => {
      state.printBatchQRFetching = true;
    },
  },
});

export const { printBatchQRClearState } = printBatchQRSlice.actions;

export default printBatchQRSlice.reducer;
