import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const addReason = createAsyncThunk(
  "admin/reason-add",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.rejectReasonPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const reasonPostSlice = createSlice({
  name: "reason-add",
  initialState: {
    addReasonFetching: false,
    addReasonSuccess: false,
    addReasonError: false,
    addReasonErrorMessage: "",
  },
  reducers: {
    clearAddReasonState: (state) => {
      state.addReasonError = false;
      state.addReasonSuccess = false;
      state.addReasonFetching = false;

      return state;
    },
  },
  extraReducers: {
    [addReason.fulfilled]: (state, { payload }) => {
      state.addReasonFetching = false;
      state.addReasonSuccess = true;
      return state;
    },
    [addReason.rejected]: (state, action) => {
      state.addReasonFetching = false;
      state.addReasonError = true;
      state.addReasonErrorMessage = action?.payload;
    },
    [addReason.pending]: (state) => {
      state.addReasonFetching = true;
    },
  },
});

export const { clearAddReasonState } = reasonPostSlice.actions;

export default reasonPostSlice.reducer;
