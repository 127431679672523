import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const reasonEdit = createAsyncThunk(
  "admin/reason-edit",
  async ({payload, reasonID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.rejectReasonDetailsURL.replace("{id}", reasonID),
        method: "PUT",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const reasonEditSlice = createSlice({
  name: "reason-edit",
  initialState: {
    editReasonFetching: false,
    editReasonSuccess: false,
    editReasonError: false,
    editReasonErrorMessage: "",
  },
  reducers: {
    clearEditReasonState: (state) => {
      state.editReasonError = false;
      state.editReasonSuccess = false;
      state.editReasonFetching = false;

      return state;
    },
  },
  extraReducers: {
    [reasonEdit.fulfilled]: (state, { payload }) => {
      state.editReasonFetching = false;
      state.editReasonSuccess = true;
      return state;
    },
    [reasonEdit.rejected]: (state, action) => {
      state.editReasonFetching = false;
      state.editReasonError = true;
      state.editReasonErrorMessage = action?.payload;
    },
    [reasonEdit.pending]: (state) => {
      state.editReasonFetching = true;
    },
  },
});
export const { clearEditReasonState } = reasonEditSlice.actions;

export default reasonEditSlice.reducer;
