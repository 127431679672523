import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading1 from "../components/custom/loading1";

//Dashboard
const Index = lazy(() => import("../views/dashboard/index"));

//Users
const Users = lazy(() => import("../views/Users/index"));
const UserDashboard = lazy(() => import("../views/Users/Dashboard/Dashboard"));


//Staffs
const Staffs = lazy(() => import("../views/Staffs/UserList"));

//Recent Scans
const RecentScans = lazy(() => import("../views/RecentScans/index"));
const FailedScans = lazy(() => import("../views/FailedScans/index"));

//Redeem Requests
const RedeemRequests = lazy(() => import("../views/RedeemRequests/index"));

//Approved Scans
const ApprovedScans = lazy(() => import("../views/ApprovedScans/index"));


//Points
const Points = lazy(() => import("../views/Points/Index"));


//Barcode
const BarcodeDashboard = lazy(() => import("../views/Barcode/Dashboard"));
const BarcodeList = lazy(() => import("../views/Barcode/Barcodes"));

//Settings
const Settings = lazy(() => import("../views/settings/index"));

//Profile
const UserProfile = lazy(() => import("../views/Profile/ProfileDashboard"));

const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={200}>
        <Suspense fallback={<Loading1 />}>
          <Switch>
            <Route path="/dashboard" exact component={Index} />

            <Route path="/users/:active_tab?" exact component={Users} />
            <Route path="/users/:userID/:active_tab?" component={UserDashboard} />
            
            <Route exact path="/staff" component={Staffs} />

            <Route exact path="/recent-scans" component={RecentScans} />
            <Route exact path="/approved-scans" component={ApprovedScans} />
            <Route exact path="/failed-scans" component={FailedScans} />
            <Route exact path="/redeem-request" component={RedeemRequests} />

            <Route exact path="/points" component={Points} />
  
            <Route exact path="/barcode/:active_tab?" component={BarcodeDashboard} />
            <Route exact path="/barcode/barcode-list/:barcodeID" component={BarcodeList} />

            <Route path="/settings/:active_tab?" component={Settings} />

            <Route path="/staff-profile" component={UserProfile} />
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
