import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchALlPoints = createAsyncThunk(
  "admin/all-points-list",
  async ({limit ,offset ,search}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.pointsListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}", search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const allPointListSlice = createSlice({
  name: "all-points-list",
  initialState: {
    allPoints: [],
    allPointsFetching: false,
    allPointsSuccess: false,
    allPointsError: false,
    allPointsErrorMessage: "",
    allPointsCount: null,
  },
  reducers: {
    allPointsClearState: (state) => {
      state.allPointsError = false;
      state.allPointsSuccess = false;
      state.allPointsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchALlPoints.fulfilled]: (state, { payload }) => {
      state.allPoints = [];
      payload.data.results.forEach((item) => state.allPoints.push(item));
      state.allPointsCount = payload.data.count;
      state.allPointsFetching = false;
      state.allPointsSuccess = true;
      return state;
    },
    [fetchALlPoints.rejected]: (state, action) => {
      state.allPointsFetching = false;
      state.allPointsError = true;
      state.allPointsErrorMessage = action?.payload;
    },
    [fetchALlPoints.pending]: (state) => {
      state.allPointsFetching = true;
    },
  },
});

export const { allPointsClearState } = allPointListSlice.actions;

export default allPointListSlice.reducer;
