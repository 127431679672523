import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchNewRequestList = createAsyncThunk(
  "admin/new-fitters-list",
  async ({ limit, offset ,search }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.unverifiedUserListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}", search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const newRequestListSlice = createSlice({
  name: "new-fitters-list",
  initialState: {
    newRequestList: [],
    newRequestFetching: false,
    newRequestSuccess: false,
    newRequestError: false,
    newRequestErrorMessage: "",
    newRequestCount: null,
  },
  reducers: {
    newRequestClearState: (state) => {
      state.newRequestError = false;
      state.newRequestSuccess = false;
      state.newRequestFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchNewRequestList.fulfilled]: (state, { payload }) => {
      state.newRequestList = [];
      payload.data.results.forEach((item) => state.newRequestList.push(item));
      state.newRequestCount = payload.data.count;
      state.newRequestFetching = false;
      state.newRequestSuccess = true;
      return state;
    },
    [fetchNewRequestList.rejected]: (state, action) => {
      state.newRequestFetching = false;
      state.newRequestError = true;
      state.newRequestErrorMessage = action?.payload;
    },
    [fetchNewRequestList.pending]: (state) => {
      state.newRequestFetching = true;
    },
  },
});

export const { newRequestClearState } = newRequestListSlice.actions;

export default newRequestListSlice.reducer;
