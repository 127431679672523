import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const userPointRedeem = createAsyncThunk(
  "admin/user-point-redeem",
  async ({ payload ,userID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.userPointRedeemURL.replace("{id}", userID),
        method: "PUT",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const userPointRedeemSlice = createSlice({
  name: "user-point-redeem",
  initialState: {
    userPointRedeemFetching: false,
    userPointRedeemSuccess: false,
    userPointRedeemError: false,
    userPointRedeemErrorMessage: "",
  },
  reducers: {
    clearUserPointRedeemState: (state) => {
      state.userPointRedeemError = false;
      state.userPointRedeemSuccess = false;
      state.userPointRedeemFetching = false;

      return state;
    },
  },
  extraReducers: {
    [userPointRedeem.fulfilled]: (state, { payload }) => {
      state.userPointRedeemFetching = false;
      state.userPointRedeemSuccess = true;
      return state;
    },
    [userPointRedeem.rejected]: (state, action) => {
      state.userPointRedeemFetching = false;
      state.userPointRedeemError = true;
      state.userPointRedeemErrorMessage = action?.payload;
    },
    [userPointRedeem.pending]: (state) => {
      state.userPointRedeemFetching = true;
    },
  },
});

export const { clearUserPointRedeemState } = userPointRedeemSlice.actions;

export default userPointRedeemSlice.reducer;
